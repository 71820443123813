<template lang="pug">
.box-bottom
    .store
        img(:src='bottomImg')
        .store-title
            .title {{title}}
            .label {{topic}}
            .bot(@click="promptly") {{botname}}
</template>
<script>
import {config} from "@/utils"
export default {
    name: 'bottoms',
    props: {
        bottomImg: {
            type: String,
            required: true,
        },
        topic: {
            type: String,
            required: true,
        },
        botname: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },
    mounted () {
     
    },

    created(){
    },

    methods: {
        promptly(){
            if(this.title=="作品存证" || this.title=="网页存证"){
                window.open(this.config.gongxinyi)
            }else{
                // source(来源) 1:人是云    2:识权识美
                if(localStorage.getItem('_RedirectData') !=null){
                    let ac=JSON.parse(localStorage.getItem('_RedirectData'))
                    let url=`${config.kefu}/frontend?source=2`+'&token='+ac.token+'&usertype='+ac.usertype+'&chat_role=1'
                    window.open(url)
                }else{
                    let url=`${config.kefu}/frontend?source=2`+'&chat_role=1'
                    window.open(url)
                }
            }
            

        }

    },

    data () {
        return {
          
            
        }
    }
}
</script>
<style lang="less" scoped>
.box-bottom{
  
   .store{
        position: relative;
        img{
            height: 2.6rem;
        }
        .store-title{
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            .title{
                color: #FFFFFF;
                font-size: .32rem;

            }
            .label{
                width: 5.54rem;
                margin-top: .1rem;
                font-size: .24rem;
                color: #FFFFFF;

            }
            .bot{
                margin: 0 auto;
                margin-top: .2rem;
                background-color: #FF6B02;
                width: 1.6rem;
                height: .48rem;
                color: #FFFFFF;
                border-radius:.03rem;
                font-size: .24rem;
                line-height: .48rem;
                text-align: center;
            }

        }

    }
}
 
</style>