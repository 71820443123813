<template lang="pug">
.box-header
    .banner
        img(:src='headerImg')
        .top(:class="[activeIndex == 2 ? 'actives' : '']") 
            .title(:class="[activeIndex == 3 ? 'active' : '']")   {{title}}
            .topic(:class="[activeIndex == 3 ? 'active' : '']")   {{topic}}
            .bot(@click='promptly' :class="[botname == '免费咨询律师' ? 'bots' : '']")  {{botname}}
</template>
<script>
import {config} from "@/utils"
export default {
    name: 'banner',
    props: {
        headerImg: {
            type: String,
            required: true,
        },
        topic: {
            type: String,
            required: true,
        },
        botname: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        activeIndex:{
            type: Number,
            required: true,

        }
    },
    mounted () {
     
    },

    created(){
    },

    methods: {
        promptly(){
            if(this.title=="作品存证" || this.title=="网页取证"){
                window.open(this.config.gongxinyi)
            }else{
                // source(来源) 1:人是云    2:识权识美
                if(localStorage.getItem('_RedirectData') !=null){
                    let ac=JSON.parse(localStorage.getItem('_RedirectData'))
                    let url=`${config.kefu}/frontend?source=2`+'&token='+ac.token+'&usertype='+ac.usertype+'&chat_role=1'
                    window.open(url)
                }else{
                    let url=`${config.kefu}/frontend?source=2`+'&chat_role=1'
                    window.open(url)
                }
            }
            

        }

    },

    data () {
        return {
          
            
        }
    }
}
</script>
<style lang="less" scoped>
.box-header{
    height: 3.56rem;
    
    .banner{
        // background: url(./images/banner.png);
        // background-size: cover;
        // background-repeat: no-repeat;
        height: 3.2rem;
        position: relative;
        img{
            position: absolute;
            height: 3.56rem;
        }
        .top{
            // padding: 35px 15px;
            padding: .72rem  .3rem;

            color: #FFFFFF;
            position: absolute;
            .active{
                color: #333333;
            }

            .title{
                font-size: .4rem;
                font-family: Source Han Sans CN;
                font-weight: bold;


            }
            .topic{
                margin-top: .24rem;
                font-size: .24rem;
                font-family: Source Han Sans CN;
                font-weight: 400;

            }
            .bot{
                margin-top: .3rem;
                font-size: .24rem;
                background-color: #FF6B02;
                width: 1.3rem;
                height: .48rem;
                color: #FFFFFF;
                border-radius: .03rem;
                line-height:  .48rem;
                text-align: center;

            }
            .bots{
                width: 1.8rem;
            }
        }
        .actives{
            padding: .72rem  .3rem;
            color: #FFFFFF;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;

            .title{
                font-size: .4rem;
                font-family: Source Han Sans CN;
                font-weight: bold;


            }
            .topic{
                width: 7rem;
                margin-top: .24rem;
                font-size: .24rem;
                font-family: Source Han Sans CN;
                font-weight: 400;

            }
            .bot{
                margin: 0 auto;
                margin-top: .3rem;
                font-size: .24rem;
                background-color: #FF6B02;
                width: 1.3rem;
                height: .48rem;
                color: #FFFFFF;
                border-radius: .03rem;
                line-height:  .48rem;
                text-align: center;

            }

        }
    }

}
 
</style>